import { object } from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Wrap } from '../components/sections-wrap';
import { Question, Header, Disclaimer } from '../components/questions-and-answers';
import * as styles from './templates.module.scss';

function SingleQuestionsTemplate({ data }) {
  const { pageSettings } = data;
  const { questionDetailPageTitle, disclaimer, shortDisclaimer } = pageSettings;
  return (
    <div className={styles.singleQuestionContainer}>
      <Wrap>
        <div className={styles.titleContainer}>
          <Link to="/questions" className={styles.goBack}>Q&A > Question</Link>
          {questionDetailPageTitle && (
          <h1 className={styles.titlePage}>
            {questionDetailPageTitle}
          </h1>
          )}
        </div>
      </Wrap>
      <Question
        data={data?.contentfulPageQa}
        title={questionDetailPageTitle}
        shortDisclaimer={shortDisclaimer?.shortDisclaimer}
        fullTexts
      />
      <Header
        titleText={`
        Still can’t find what you’re looking
        <br />
        for? Ask a laywer
      `}
        displayCaseAssesment
      />
      <Disclaimer disclaimerText={disclaimer?.disclaimer} />
    </div>
  );
}

export const query = graphql`
  query singleQuestion($slug: String) {
    contentfulPageQa(slug: {eq: $slug}){
      slug
      category
      askedDate
      answer {
        raw
      }
      question {
        question
      }
      author {
        title
        uri
        attorney {
          authorImage {
            image {
              gatsbyImage(width:35, placeholder: NONE)
            }
            altText
          }
          firstName
          lastName
        }
      }
    }
    pageSettings: contentfulPageQaSettings(id: {eq: "12b0c95c-87d6-5514-ab0d-8d9373e64ae5"}) {
      questionDetailPageTitle
      disclaimer {
        disclaimer
      }
      shortDisclaimer {
        shortDisclaimer
      }
    }
  }  
`;

SingleQuestionsTemplate.propTypes = {
  data: object,
};

SingleQuestionsTemplate.defaultProps = {
  data: {},
};

export default SingleQuestionsTemplate;
export { Head } from '../components/seo/seo';
